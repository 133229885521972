import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../mailapp/MainPage.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../mailapp/Auth/registerUser.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../mailapp/Auth/loginUser.vue')
  },
  {
    path: '/quickLogin',
    name: 'quickLogin',
    component: () => import('../mailapp/Auth/quickLogin.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../mailapp/Data/MainCenter.vue')
  },
  {
    path: '/check',
    name: 'check',
    component: () => import('../mailapp/CheckAuth.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router

//解决vue路由重复导航错误